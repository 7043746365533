import { Link, Element } from 'react-scroll';
import { slide as Menu } from 'react-burger-menu';

import React from "react";
import MediaQuery from "react-responsive";
import cx from "classnames";
import gus from "./css/gus.module.scss";
import styles from "./css/app.module.scss";
import logo from "./img/logo.png";
import john from "./img/john.jpg";
import jessie from "./img/jessie.png";

const renderEmail = (isBlock) => {
  const email = "hello@thinkisla.com";

  if (isBlock) {
    return (
      <a className={cx(gus.marginTop2, styles.primaryBtn, styles.btn)}
         href={`mailto:${email}`}>{email}</a>
    );
  }
  else {
    return (
        <a href={`mailto:${email}`}>{email}</a>
    );
  }
};


class Theme extends React.Component {
    constructor(props) {
        super(props);
        this.state = this.setupFromProps(props);
    }

    componentDidMount() {
        document.addEventListener('scroll', this.trackScrolling);
    }

    setupFromProps(props) {
        const schedule = {
            "one": [
                {
                    morning: "Arrivals in San Antonio, Texas",
                    afternoon: "Arrivals in San Antonio, Texas",
                    evening: "Arrivals in San Antonio, Texas",
                },
                {
                    morning: undefined,
                    afternoon: undefined,
                    evening: (
                        <div>
                            <p>Welcome reception</p>
                            <p>Location: TBD</p>
                        </div>
                    ),
                }
            ],
            "two": [
                {
                    morning: (
                        <div>
                            <p>Breakfast</p>
                        </div>
                    ),
                    afternoon: (
                        <div>
                            <p>Lunch</p>
                        </div>
                    ),
                    evening: (
                        <div>
                            <p>Off-site activity and dinner</p>
                            <p>Location: TBD</p>
                        </div>
                    ),
                },
                {
                    morning: (
                        <div>
                            <p>Collaboration sessions and team meetings</p>
                            <p>Location: Briscoe Western Art Museum</p>
                        </div>
                    ),
                    afternoon: (
                        <div>
                            <p>Team meetings and Unconference</p>
                            <p>Location: Briscoe Western Art Museum</p>
                        </div>
                    ),
                    evening: undefined
                },
            ],
            "three": [
                {
                    morning: (
                        <div>
                            <p>Breakfast</p>
                        </div>
                    ),
                    afternoon: (
                        <div>
                            <p>Lunch</p>
                        </div>
                    ),
                    evening: (
                        <div>
                            <p>FormFast Gala</p>
                            <p>Location: Hotel Contessa</p>
                        </div>
                    ),
                },
                {
                    morning: (
                        <div>
                            <p>General session</p>
                            <p>Location: Briscoe Western Art Museum</p>
                        </div>
                    ),
                    afternoon: (
                        <div>
                            <p>General session</p>
                            <p>Location: Briscoe Western Art Museum</p>
                        </div>
                    ),
                    evening: undefined
                }
            ],
            "four": [
                {
                    morning: (
                        <div>
                            <p>Breakfast</p>
                        </div>
                    ),
                    afternoon: (
                        <div>
                            <p>Lunch</p>
                        </div>
                    ),
                    evening: undefined,
                },

                {
                    morning: (
                        <div>
                            <p>Charity event and closing sessions</p>
                            <p>Location: Hotel Contessa</p>
                        </div>
                    ),
                    afternoon: (
                        <div>
                            <p>Departure back to home city</p>
                        </div>
                    ),
                    evening: undefined,
                },
            ]
        };

        return {
            selectedDay: "one",
            schedule: schedule,
            headerState: "transparent",
            menuOpen: false,
        };
    }


    trackScrolling = () => {
        if(window.scrollY > 90) {
            this.setState({
                headerState: "fixed",
            });
        } else {
            this.setState({
                headerState: "transparent",
            });
        }
    };

    renderSchedule = (schedule, index) => {
        return(
            <tr key={index}>
                <td>
                    {schedule.morning}
                </td>
                <td>
                    {schedule.afternoon}
                </td>
                <td>
                    {schedule.evening}
                </td>
            </tr>
        );
    };

    renderNavigation = () => {
        return(
            <ul>
                <li className={styles.logo}>
                    <img src={logo}
                    alt={"ThinkIsla"}
                    style={
                    this.state.headerState === "transparent" ? ({
                        maxWidth: '65px',

                    }) : ({
                        maxWidth: '40px',
                    })}
                />
                </li>
                <li
                    style={
                    this.state.headerState === "transparent" ? ({
                        padding: '45px 0px',

                    }) : ({
                        padding: '17px 0px',
                    })}
                >
                    <Link
                        activeClass="active"
                        to="overview"
                        spy
                        smooth
                        duration={500}
                        onClick={() => {
                            this.setState({menuOpen: false});
                        }}
                    >
                        Overview
                    </Link>
                </li>
                <li
                    style={
                    this.state.headerState === "transparent" ? ({
                        padding: '45px 0px',

                    }) : ({
                        padding: '17px 0px',
                    })}
                >
                    <Link
                        activeClass="active"
                        to="about"
                        spy
                        smooth
                        duration={500}
                        onClick={() => {
                            this.setState({menuOpen: false});
                        }}
                    >
                        About
                    </Link>
                </li>
                <li
                    style={
                    this.state.headerState === "transparent" ? ({
                        padding: '45px 0px',

                    }) : ({
                        padding: '17px 0px',
                    })}
                >
                    <Link
                        activeClass="active"
                        to="contact"
                        spy
                        smooth
                        duration={500}
                        onClick={() => {
                            this.setState({menuOpen: false});
                        }}
                    >
                        Contact
                    </Link>
                </li>
            </ul>
        );
    };

    render() {
        return (
            <div className={cx(styles.theme1, styles.theme)}>
                <div className={styles.navigation} style={
                    this.state.headerState === "transparent" ? ({
                        background: 'transparent',
                        position: 'absolute',

                    }) : ({
                        background: '#006BB6',
                        position: 'fixed',
                    })}>
                    <MediaQuery query="(min-width: 750px)">
                        <div>
                            {this.renderNavigation()}
                        </div>
                    </MediaQuery>
                    <MediaQuery query="(max-width: 749px)">
                        <div>
                            <Menu isOpen={this.state.menuOpen}>
                                <div className={styles.burgerNavStyle}>
                                    {this.renderNavigation()}
                                </div>
                            </Menu>
                        </div>
                    </MediaQuery>
                </div>
                <div className={styles.section1} id="section1">
                    <div className={styles.landingBackground}/>
                    <div className={styles.landingOverlay}/>
                    <div className={styles.landingContent}>
                        <h1>Helping you run and scale your technology company</h1>
                        <p>San Juan, PR</p>
                        <div>
                            <Link
                                activeClass="active"
                                to="overview"
                                spy
                                smooth
                                duration={500}
                                onClick={() => {
                                    this.setState({menuOpen: false});
                                }}
                            >
                            <span> </span>
                            </Link>

                        </div>
                    </div>


                </div>
                <Element name="overview">
                    <div className={cx(styles.section2, gus.padding6)}>
                        <h2>Overview</h2>
                        <p>
                            We are a business management and technology consulting company.  For start-ups and small
                            businesses who need help with business management we offer consulting on billing,
                            accounting, and budgeting.  We also provide training for managers who are in high growth
                            companies and need to understand how to manage teams of people instead of just individual
                            contributors.
                            <br /> <br />
                            On the technology side of things we offer a suite of services that include design, SEO,
                            marketing, and development. We help companies increase their growth through our knowledge
                            of search engine optimization and user experience.  We help them optimize their pages as
                            well as localize them for international markets.   We also are experts in user experience
                            and help reduce churn and improve the overall usability of products.   This includes
                            accessibility improvements such as 508 compliance.
                            <br /><br />

                            We also help high growth companies that need new features developed or performance of the
                            product improved.  We can optimize Microsoft SQL, MySQL, Postgresql, and Couchbase databases
                            and improve their overall availability.   We also develop both the API and Frontend of
                            features in both web applications and mobile applications.
                            <br /> <br />
                            If you have any questions, please email {renderEmail()}
                        </p>
                    </div>
                </Element>
                <Element name="about">
                    <div className={cx(styles.section3, gus.padding6)}>
                        <h2>About</h2>
                        <div className={cx(styles.location, gus.marginTop5)}>
                            <div className={cx(styles.floatLeft, gus.floatLeft)}>
                                <p>
                                    John is an entrepreneur and software developer. As the founder of multiple
                                    start-ups as well as an early employee into both start-ups that have been acquired
                                    as well an employee of large public organizations he has gained a lot of knowledge
                                    in both technology and business management.
                                    <br /><br />
                                    He is a Python and Linux developer who loves working with Redis, Memcached, Postgresql,
                                    NumPy, Pandas, React.js, and Pyramid for creating fast and interactive web
                                    applications.
                                    <br /><br />
                                    As a multiple time Google Summer of Code Mentor for the Python Software Foundation
                                    and a speaker at technology conferences he has built a following in the open source
                                    community.
                                </p>
                            </div>
                            <div className={cx(styles.floatRight, gus.floatRight)}>
                                <img src={john} alt={""} className={styles.profilePhoto} />
                            </div>
                        </div>
                        <div className={cx(styles.location, gus.marginTop8)}>
                            <div className={cx(styles.floatLeft, gus.floatLeft)}>
                              <img src={jessie} alt={""} className={styles.profilePhoto}/>
                            </div>
                            <div className={cx(styles.floatRight, gus.floatRight)}>
                              <p>
                                  Jessie is a full stack software developer who has an expertise in Javascript (React.js)
                                  and CSS. She has worked on everything from OpenSource database management software in Python
                                  for RedHat to radiation oncology software for Varian Medical Systems.
                                  <br /><br />
                                  She has experienced working with failed start-ups as well as been through a large acquisition
                                  when the small start-up she was working for, Mobius Medical Systems, was acquired by Varian.
                              </p>
                            </div>
                        </div>
                        <div className={cx(gus.marginTop4)}>
                          Our team is also experts in automation QA with selenium and headless browsers with puppeteer so we can
                          verify and prevent regressions.
                        </div>
                    </div>
                </Element>
                <Element name="contact">
                    <div className={cx(styles.section2, gus.padding6)}>
                        <h2>Contact</h2>
                        <span>You may contact us at:</span>
                        <p>
                          {renderEmail(true)}
                        </p>
                    </div>
                </Element>
                <Element name="footer">
                    <section className={cx(styles.footer)}>
                        <p>
                            © Copyright 2019 <a href="https://thinkisla.com/">ThinkIsla</a> - All Rights Reserved
                        </p>
                    </section>
                </Element>
            </div>
        );
    }
}

export default Theme;
